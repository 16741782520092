import { IMediaType } from "../models";
import {
  ESocialPlatform,
  ESocialPlatformPopularity,
} from "./apply-to-be-featured-popup/enums";
import { EPayoutMethod } from "./earnings/earnings-models";

export interface IOnboardingInitConfig {
  email?: string;
  code_used?: string;
  click_id?: string;
  source_flow?: string;
  isVerifyResend?: boolean;
  isAlreadyConverted?: boolean;
  isCodeOnboarding?: boolean;
  isClosable?: boolean;
}

export interface IOnboardingCompleteConfig {
  token?: string;
  isJustSetUserName?: boolean;
}

export interface IOnboardingConfig {
  init?: IOnboardingInitConfig;
  complete?: IOnboardingCompleteConfig;
}

export interface IEmailSettings {
  marketing: boolean;
  notifications: boolean;
  payments: boolean;
  payouts: boolean;
  commissions: boolean;
}

export interface IVideoRate {
  start: number;
  end: number;
  rate: number;
}

export enum EUserType {
  User = "user",
  Featured = "featured",
}

export enum EDiscoverStatus {
  Featured = "featured",
  Hidden = "hidden",
  Denied = "denied",
}

export enum EIdVerificationStatus {
  Pending = "pending",
  InProgress = "in_progress",
  Rejected = "rejected",
  Verified = "verified",
}

export enum ESocialPlatformStatus {
  InProgress = "in_progress",
  Rejected = "rejected",
  Verified = "verified",
}

export enum EIdVerificationMediaStatus {
  InProgress = "in_progress",
  Verified = "verified",
  Retake = "retake",
}

export interface IUserEssentialInfo {
  _id: string;
  display_name: string;
  username: string;
}

export interface IProfileCompletionConfig {
  is_affiliate_profile_visited?: boolean;
  is_avatar?: boolean;
  is_bought_credit_package?: boolean;
  is_messaged_five_members?: boolean;
  is_auto_messages_visited?: boolean;
  is_auto_messages_enabled?: boolean;
  // is_broadcast_visited?: boolean;
}

export enum EGenderType {
  Male = "male",
  Female = "female",
}

export interface IUserInfo extends IUserEssentialInfo {
  auto_messages_available?: boolean;
  auto_messages_on?: boolean;
  affiliate?: string; // model._id or undefined for tiger1's
  profile_complete_config: IProfileCompletionConfig;
  latitude: number;
  longitude: number;
  app_version: string;
  avatar: IMediaType;
  bio: string;
  blocked_countries_count?: number;
  blocked_users_count?: number;
  is_boosted: boolean;
  boosted_till: string;
  block_chat: boolean;
  can_intro: boolean;
  can_sendphoto: boolean;
  can_set_rate: boolean; // default chat rate
  can_change_chat_rate: boolean; // direct chat rate
  cc_added: boolean;
  chars_per_msg: number;
  chat_id?: string; // only for direct request (e.g. won't be in discover user object) and it is the id of the chat with this user (if any)
  credit_rate: number;
  city: string;
  code_confirmed: boolean;
  code_used: string;
  code_used_is_featured: boolean;
  country: string;
  country_code: string;
  created_at: string;
  credits: number;
  deactivated: boolean;
  discover?: IMediaType;
  discover_status?: EDiscoverStatus; // fm only
  email: string;
  email_bounced: boolean;
  email_settings: IEmailSettings;
  email_verified?: boolean;
  facebook_connected?: boolean;
  featured: IMediaType[];
  featured_enquiry: boolean;
  force_discover?: boolean; // coupled with discover_status === EDiscoverStatus.Hidden will force to show discover
  force_free_chats?: boolean;
  force_free_chats_subs?: boolean;
  gender: EGenderType;
  greeting?: IMediaType;
  iap_banned: boolean;
  id_verified: boolean; // model government_id_approved
  id_verification_status?: EIdVerificationStatus | null; // only for models
  ig_url?: string;
  ig_followers?: number;
  ig_username?: string;
  is_customer?: boolean; // flag for me if I'm a paying customer and also for other users in chats for fans to see which should be marked as Paid
  is_subscribed_to?: boolean; // flag for user to see if he is subscribed to fm
  is_subscriber?: boolean; // flag for fm to see if user is subscribed to her
  is_broadcasting?: boolean; // flag for fm during sending a broadcast message (is ended with ws type broadcast_done)
  image_count?: number;
  image_rate?: number;
  intro_enabled?: boolean;
  invisible: boolean;
  subscribers_count: number;
  subscription_enabled?: boolean;
  subscription_free_chats?: boolean;
  subscription_rate: number;
  subscriptions_earnings: number; // only for fms
  is_blocked?: boolean;
  is_blocked_by?: boolean;
  is_blocked_by_country?: boolean;
  is_followee?: boolean;
  is_follower?: boolean;
  is_online: boolean;
  is_staff: boolean;
  is_staff_mo?: boolean;
  is_vip: boolean;
  last_online: string;
  // media_blocked_for: boolean; // only for chat.other_user means you've blocked this user's media
  // media_blocked_from: boolean; // only for chat.other_user means other user has blocked you from sending media
  name: string;
  new_fm_onboarding?: boolean; // only present if model onboarded using the new join flow
  oauth_facebook_without_password?: boolean;
  oauth_twitter_without_password?: boolean;
  onboarding_v2?: boolean; // only present if user onboarded using new code verification flow
  os_version: string;
  payouts?: {
    email: string;
    method: EPayoutMethod;
  };
  paid_content_policy_confirmed?: boolean;
  payout_country_code?: string | null; // only needed for patch
  position_top?: number;
  push_enabled: boolean;
  private_feed: boolean;
  region_blacklist: boolean;
  requires_moderation?: boolean; // only present for model using new new_fm_onboarding that needs to be moderated
  show_review_banner: boolean;
  show_skinscore_banner: boolean;
  show_visibility_penalty_banner: boolean;
  show_subscription_free_chats?: boolean; // show toggle in set credit rate
  social_platform?: ESocialPlatform;
  social_platform_username?: string;
  social_platform_popularity?: ESocialPlatformPopularity;
  social_platform_status?: ESocialPlatformStatus | null; // only for models
  social_platform_status_reason?: string; // only for models
  subscription_commission: number; // only for fms
  tags: string[];
  title: string;
  tier?: number | null;
  type: EUserType;
  twitter_connected?: boolean;
  udid: string;
  username_generated?: boolean; // only present if true
  video_count?: number;
  video_rates?: IVideoRate[];
  test_account?: boolean;
  has_favorites?: boolean;
  fans_30: number;
  subscriptions_30: number;
  sales_30: number;
  tips?: string[];
  // custom
  __wasGreeted?: boolean;
}

export interface IProviderConfig {
  is_guest?: boolean; // expired or no token
  app_keywords?: string; // competing apps
  global_keywords?: string; // all warning keywords used for fms
  payment_keywords?: string; // external payments
  show_promo_badges?: boolean;
  greeting_keywords?: string; // rude words in send to all
  features?: IProviderConfigFeatures;
  packages?: IProviderConfigPackage[];
  smart_logic?: boolean; // special logic for paypal ban protection
  usd_to_credit_ratio?: number;
  tips_min_amount?: number;
  tips_max_amount?: number;
  content_prices?: {
    usd: {
      step: number;
      min: {
        image: number;
        video: number;
      };
      max: {
        image: number;
        video: number;
      };
      default: {
        image: number;
        video: number;
      };
    };
    credits: {
      step: number;
      min: {
        image: number;
        video: number;
      };
      max: {
        image: number;
        video: number;
      };
      default: {
        image: number;
        video: number;
      };
    };
  };
}

export enum EFeature {
  Broadcast = "broadcast",
  ExclusiveContentBanner = "exclusive_content_banner",
  NoDiscover = "no_discover",
  ForceFreeChats = "force_free_chats",
  BankTransfer = "bank_transfer",
  PayPalTransfer = "paypal_transfer",
  CryptoTransfer = "crypto_transfer",
  Tips = "tips",
  UsdContent = "usd_content",
  CreditPackageSalesCommissions = "credit_package_sales_commissions",
  CryptoPayment = "crypto_payment",
  Vault = "vault",
}

export interface IProviderConfigFeatures {
  [EFeature.Broadcast]?: boolean;
  [EFeature.ExclusiveContentBanner]?: boolean;
  [EFeature.NoDiscover]?: boolean;
  [EFeature.ForceFreeChats]?: boolean;
  [EFeature.BankTransfer]?: boolean;
  [EFeature.PayPalTransfer]?: boolean;
  [EFeature.CryptoTransfer]?: boolean;
  [EFeature.Tips]?: boolean;
  [EFeature.UsdContent]?: boolean;
  [EFeature.CreditPackageSalesCommissions]?: boolean;
  [EFeature.CryptoPayment]?: boolean;
  [EFeature.Vault]?: boolean;
}

export interface IProviderConfigPackage {
  amount: number; // price
  credits: number;
  bonus?: number;
  default?: boolean; // selected by default
}

export enum ECreditCardBrand {
  VISA = "VISA",
  MASTER = "MASTER",
}

export interface ICreditCard {
  brand: ECreditCardBrand;
  id: string;
  last_4_numbers: string;
}

export interface ISubscriptionInfo {
  user: IUserInfo;
  amount: number;
  status: ESubscriptionInfoStatus;
  till: string; // date
}

export enum ESubscriptionInfoStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
}

export enum EAutoMessageType {
  Text = "text",
  Image = "image",
  Video = "video",
}

export interface IAutoMessageCategory {
  category: string;
  messages: IAutoMessage[];
  excerpt: string;
  sent_24h: number;
}

export interface IAutoMessage {
  _id?: string;
  type: EAutoMessageType;
  text?: string;
  media_id?: string; // bendinary
  public_id?: string; // cloudinary
  media?: IMediaType;
  price?: number;
  price_in_dollars?: boolean; // price in usd instead of Credits
  self_destruct?: boolean;
  __clientSideSendId?: boolean;
  __isUploadFailed?: boolean;
  __errorMessage?: string;
  __isBeingDeleted?: boolean;
}

export enum EAutoMessageCategory {
  User = "user",
  Follower = "follower",
  Subscriber = "subscriber",
  Fan = "fan",
}

export enum ECreditCardFormType {
  Default = "default",
  Compact = "compact",
}

export interface IMyPurchase {
  media: IMediaType;
  price: number;
  price_formatted: string;
  self_destruct: boolean;
  self_destruct_at: string | null;
  paid_at: string;
  sender: {
    _id: string;
    username: string;
    display_name: string;
    avatar: IMediaType;
  };
}

export interface IVaultMedia {
  _id: string;
  type: "image" | "video";
  public_id?: string; // item was uploaded to CLOUDINARY
  media_id?: string; // item was uploaded to BENDINARY
  width: number;
  height: number;
  video_length?: number;
  user_id: string;
  name: string;
  created_at: string; // string timestamp
  updated_at: string; // string timestamp
  rt: string; // src url
  st: string; // src url
  th: string; // src url
  video_url?: string; // src url
  is_current_chat: boolean;
  is_current_chat_paid: boolean;
}

export interface IIdVerificationInfo {
  media: null | {
    front?: {
      public_id: string;
      rt: string;
      st: string;
      th: string;
      type: "front";
      status: EIdVerificationMediaStatus;
      status_reason?: string;
    };
    back?: {
      public_id: string;
      rt: string;
      st: string;
      th: string;
      type: "back";
      status: EIdVerificationMediaStatus;
      status_reason?: string;
    };
    selfie?: {
      public_id: string;
      rt: string;
      st: string;
      th: string;
      type: "selfie";
      status: EIdVerificationMediaStatus;
      status_reason?: string;
    };
    video_selfie?: {
      public_id: string;
      rt: string;
      st: string;
      th: string;
      type: "video_selfie";
      status: EIdVerificationMediaStatus;
      status_reason?: string;
      video_url: string;
    };
  };
  status: EIdVerificationStatus;
}

export interface BlockedUser {
  _id: string;
  username: string;
  display_name: string;
  avatar?: IMediaType;
}

export interface BlockedCountry {
  code: string;
  name: string;
  blocked: boolean;
}

export enum ECryptoTransactionPaymentStatus {
  Waiting = "Waiting",
  Failed = "Failed",
  Success = "Success",
}
